import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="page-content bg-light">
      <div className="container">
        <div className="pt-5 text-center">
          <h1 className="text-muted text-uppercase">Not Found</h1>
          <p>This page does not exist.</p>
          <Link className="btn btn-primary m-2" role="button" to="/">
            Take Me Home
          </Link>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
